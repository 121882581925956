







































































































































import {
  Component,
  Vue
} from "vue-property-decorator";
import {
  apiUserList,
  apiUserSearchList,
  apiUserSetLabel
} from "@/api/user/user"
import {
  PageMode
} from "@/utils/type"
import {
  RequestPaging
} from "@/utils/util"
import LsPagination from "@/components/ls-pagination.vue"
import ExportData from '@/components/export-data/index.vue'

@Component({
  components: {
    LsPagination,
    ExportData
  },
})
export default class UserManagement extends Vue {
  /** S Data **/
  pickerOptions = {
    shortcuts: [{
      text: "最近一周",
      onClick(picker: any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit("pick", [start, end]);
      },
    },
      {
        text: "最近一个月",
        onClick(picker: any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "最近三个月",
        onClick(picker: any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          picker.$emit("pick", [start, end]);
        },
      },
    ],
  };
  form = {
    keyword: '', // 用户信息
    level: '', // 用户等级
    label_id: '', // 用户标签
    min_amount: '', // 消费最小金额
    max_amount: '', // 消费最大金额
    source: '', // 注册来源
    create_start_time: 0, // 注册开始时间（传时间戳）
    create_end_time: 0, // 注册结算时间（传时间戳）
  };
  // 日期选择器数据
  timeForm = []

  // 设置用户标签弹出框
  dialogVisible = false
  // 选中用户标签
  labelValue = []
  // 用户选择框数据
  userLevelList = {}
  userLabelList = {}
  sourceList = {}
  // 分页查询
  pager: RequestPaging = new RequestPaging()
  // 被选中的用户id
  multipleSelection = []
  /** E Data **/

  /** S Methods **/
  apiUserList = apiUserList  // 传递给导出组件的api

  // 选中的用户触发事件
  handleSelectionChange(val: any) {
    this.multipleSelection = val;
  }

  // 查询按钮
  query() {
    if (this.form.min_amount && this.form.max_amount) {
      if (this.form.min_amount >= this.form.max_amount) {
        return this.$message.error('消费金额最低价应小于最高价')
      }
    }
    this.getUserList()
  }

  //获取用户列表数据
  getUserList() {
    this.pager
        .request({
          callback: apiUserList,
          params: {
            ...this.form,
          },
        })
  }

  // 获取用户搜索条件列表
  getUserSearchList() {
    apiUserSearchList()
        .then((res: any) => {
          this.userLevelList = res.user_level_list
          this.userLabelList = res.user_label_list
          this.sourceList = res.source_list
        })
  }

  // 转换为时间
  add(m: number) {
    return m < 10 ? "0" + m : m;
  }

  baseTime(event: any) {
    const d = new Date(event);
    return `${this.add(d.getFullYear())}-${this.add(
        d.getMonth() + 1
    )}-${this.add(d.getDate())} ${this.add(d.getHours())}:${this.add(
        d.getMinutes()
    )}:${this.add(d.getSeconds())}`;
  }

  // 拆分日期选择器时间
  splitTime() {
    if (this.timeForm != null) {
      this.form.create_start_time = (new Date(this.timeForm[0]).getTime()) / 1000
      this.form.create_end_time = (new Date(this.timeForm[1]).getTime()) / 1000
    }
  }

  // 重置按钮
  onReset() {
    this.form = {
      keyword: '', // 用户信息
      level: '', // 用户等级
      label_id: '', // 用户标签
      min_amount: '', // 消费最小金额
      max_amount: '', // 消费最大金额
      source: '', // 注册来源
      create_start_time: 0, // 注册开始时间（传时间戳）
      create_end_time: 0, // 注册结算时间（传时间戳）
    };
    this.timeForm = []
    this.getUserList()
  }

  // 打开设置用户标签弹窗
  openDialogVisible() {
    if (!this.multipleSelection) {
      this.$message.error("请选择用户!");
      return
    }
    if (this.multipleSelection.length <= 0) {
      this.$message.error("请选择用户!");
      return
    }
    this.dialogVisible = true
  }

  // 设置用户标签
  changeUserLabel() {
    let userIds: Array<Object> = []
    this.multipleSelection.forEach((item: any) => {
      userIds = [...userIds, item.id]
    })
    apiUserSetLabel({
      user_ids: userIds,
      label_ids: this.labelValue,
    })
        .then(res => {
        })
        .catch(res => {
          console.log(res)
        })
    this.dialogVisible = false
  }

  // 标签弹框关闭事件
  closeDialog() {
    this.labelValue = []
  }

  // 用户详情
  DetailsClick(item: any) {
    this.$router.push({
      path: "/user/user_details",
      query: {
        id: item.id,
      }
    })
  }

  /** E Methods **/

  /** S Life Cycle **/
  created() {
    this.getUserList()
    this.getUserSearchList()
  }

  /** E Life Cycle **/
}
